import React from "react"

export default function PageHeader({ text }) {
  return (
    <>
      <h1 className="uppercase tracking-wider border-b-2 border-primary inline-block text-xs">
        {text}
      </h1>
    </>
  )
}
