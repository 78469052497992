import React from "react"
import Button from "../components/common/Button"
import PageHeader from "../components/common/PageHeader"
import Layout from "../components/layout"

export default function CtrmEn() {
  return (
    <Layout lang="en">
      <div className="space-y-8">
        <PageHeader text="Commodity trading &amp; risk management system" />

        <div
          id="wp-page"
          className="grid grid-cols-1 md:grid-cols-2 gap-8 space-y-4 lg:space-y-0"
        >
          <div>
            <div className="space-y-4 bg-sixth p-4">
              <h3>Sign in</h3>
              <form className="space-y-4">
                <formgroup className="flex flex-col ">
                  <label for="username" className="pb-2">
                    Username{" "}
                  </label>
                  <input type="text" name="username" className="p-4" />
                </formgroup>
                <formgroup className="flex flex-col">
                  <label for="password" className="pb-2">
                    Password{" "}
                  </label>
                  <input type="password" name="password" className="p-4" />
                </formgroup>
                <Button link="" text="Submit" />
              </form>
            </div>
          </div>
          <div>
            <div className="space-y-4 leading-relaxed">
              <h2 className="">Risk- and Revenue Reporting CTRM</h2>
              <div>
                <p>
                  Bodecker Partners´ own developed web-based CTRM is tailored to
                  investors of renewable assets. It provides a clear overview
                  and near real-time status of portfolio positions, value and
                  risks. We offer this solution as part of our Revenue
                  Management package or as a stand-alone solution with monthly
                  subscription.
                </p>
              </div>
              <h4 className="font-bold">Our CTRM-system provides:</h4>
              <div>
                <ul>
                  <li>Daily position & portfolio market value and risks</li>
                  <li>
                    PPAs, options, std power products and green instruments
                  </li>
                  products and Electricity certificates
                  <li>Deal container -</li>
                  Counterparty container and credit risk exposure
                  <li>
                    Price- and capture rate curves for each Nordic price area
                  </li>
                </ul>
              </div>
              <div className="space-y-4 bg-sixth p-4">
                <div>
                  <h4 className="font-bold">
                    Interested in a demo or more information?
                  </h4>
                  <p>
                    Contact{" "}
                    <a href="mailto:sevdie@bodeckerpartners.com">
                      sevdie@bodeckerpartners.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
